import React from "react";
import { graphql } from "gatsby";
import Layout from "../components/layouts";
import Hero from "../components/common/hero";
import Logo from "../components/common/logo";
import RegenerativePledge from "../components/common/regenerativePledge";
import BreadCrumb from "../components/common/breadcrumb";
import Instagram from "../components/common/Instagram";
import Seo from "../components/seo";
import SiteLink from "../components/sitemap";

export default function SiteMap({
  data: { sanitySitemapPage, sanitySitesettings },
}) {
  let seo;
  let hero;
  let breadcrumb;
  let regenerative;

  sanitySitemapPage?.pageBuilder?.forEach((element) => {
    if (element._type === "seo") {
      seo = element;
    } else if (element._type === "heroSection") {
      hero = element;
    } else if (element._type === "breadcrumb") {
      breadcrumb = element;
    } else if (element._type === "regenerative") {
      regenerative = element;
    }
  });

  return (
    <Layout sitemap={false}>
      <Seo data={seo} />
      <Hero heroSection={hero} h2Size="text-3xl sm:text-[60px]" h2P="pb-7" />
      <Logo logo={sanitySitesettings?.logo} />
      {sanitySitemapPage?.sitemap && (
        <SiteLink data={sanitySitemapPage?.sitemap} />
      )}

      {breadcrumb && <BreadCrumb path={breadcrumb} />}

      <Instagram />
      {regenerative && (
        <div>
          <RegenerativePledge
            data={regenerative}
            min_h="min-h-[72vh]"
            pb="pb-[80px]"
            px="px-[10px]"
          />
          <Logo logo={sanitySitesettings?.logo} />
        </div>
      )}
    </Layout>
  );
}

export const query = graphql`
  query {
    sanitySitemapPage {
      pageBuilder {
        ... on SanityHeroSection {
          _type
          heading1
          heading2
          alignment
          ctaButton {
            link
            title
            type
            variant
            formId
            downloadLink
          }
          bgImages {
            alt
            caption
            asset {
              gatsbyImageData(placeholder: BLURRED, fit: FILLMAX, formats: WEBP)
            }
          }
        }

        ... on SanityBreadcrumb {
          _type
          breadLink {
            breadPath
            nameLink
          }
          sectionBg {
            asset {
              url
            }
          }
        }

        ... on SanitySeo {
          _type
          description
          keywords
          ldSchema
          pagehandle
          title
        }
        ... on SanityRegenerative {
          _type
          heading
          _rawContent
          _rawAuthor
          sectionBg {
            asset {
              url
            }
          }
        }
      }
      sitemap {
        label
        childs {
          formId
          label
          path
          type
        }
      }
    }
    sanitySitesettings {
      logo {
        alt
        asset {
          gatsbyImageData(fit: FILLMAX, placeholder: BLURRED, formats: WEBP)
        }
      }
    }
  }
`;
